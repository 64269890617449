import ReactDOM from "react-dom/client";
import App from "./app";
import { reportWebVitals } from "./report-web-vitals";

function sendToGoogleAnalytics({
  name,
  delta,
  value,
  id,
  rating,
}: {
  name: string;
  delta: number;
  value: number;
  id: string;
  rating: number;
}) {
  const eventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
    metric_rating: rating, // Optional.
  };

  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  (window as any).gtag("event", name, eventParams);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

reportWebVitals(sendToGoogleAnalytics);
