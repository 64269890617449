export async function reportWebVitals(onPerfEntry: any) {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    const { onCLS, onINP, onLCP, onFCP, onTTFB } = await import("web-vitals");
    onCLS(onPerfEntry, { reportAllChanges: true });
    onINP(onPerfEntry, { reportAllChanges: true });
    onLCP(onPerfEntry, { reportAllChanges: true });
    onFCP(onPerfEntry, { reportAllChanges: true });
    onTTFB(onPerfEntry, { reportAllChanges: true });
  }
}
