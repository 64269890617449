import { useState } from "react";
import {
  useUserTaskPermissionsDrawerQuery,
  useUserTaskPermissionsDrawer_ProductTaskTypesQuery,
  useUserTaskPermissionsDrawer_CreatePermissionMutation,
  useUserTaskPermissionsDrawer_DeletePermissionMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import { getDisplayMessageForError } from "../../util/error-helper";
import { uniqBy } from "lodash";

interface Options {
  onClose: () => void;
  onPermissionsUpdated: () => void;
  userId: number;
  vendorId?: number;
}

export function useUserTaskPermissionsDrawer(options: Options) {
  const { onClose, onPermissionsUpdated } = options;
  const [vendorId, setVendorId] = useState<number | null>(options.vendorId ?? null);
  const taskPermissionsQueryResult = useUserTaskPermissionsDrawerQuery({
    fetchPolicy: "network-only",
    variables: {
      userId: options.userId,
      vendorId: options.vendorId ?? null,
    },
  });
  const productTaskTypesQueryResult = useUserTaskPermissionsDrawer_ProductTaskTypesQuery({
    fetchPolicy: "network-only",
  });
  const [createUserTaskPermissionMutation] = useUserTaskPermissionsDrawer_CreatePermissionMutation();
  const [deleteUserTaskPermissionMutation] = useUserTaskPermissionsDrawer_DeletePermissionMutation();
  const toast = useToast();
  const [wildcardProductId, setWildcardProductId] = useState("");
  const [wildcardTaskTypeId, setWildcardTaskTypeId] = useState("");

  function handleClose() {
    onClose();
  }

  function onVendorChange(vendorId: number | null) {
    setVendorId(vendorId);
  }

  async function createUserTaskPermission(productId: string | null, taskTypeId: string | null) {
    if (!vendorId) {
      toast({ title: "Please select a vendor", status: "error" });
      return;
    }
    try {
      await createUserTaskPermissionMutation({
        variables: {
          input: {
            userId: options.userId,
            vendorId,
            productId,
            taskTypeId,
          },
        },
      });
      taskPermissionsQueryResult.refetch();
      onPermissionsUpdated();
    } catch (e: any) {
      toast({ title: "Unable to Save", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  async function deleteUserTaskPermission(userTaskPermissionId: number) {
    try {
      await deleteUserTaskPermissionMutation({
        variables: {
          input: {
            userTaskPermissionId,
          },
        },
      });
      taskPermissionsQueryResult.refetch();
      onPermissionsUpdated();
    } catch (e: any) {
      toast({ title: "Unable to Delete", description: getDisplayMessageForError(e), status: "error" });
    }
  }

  const user = taskPermissionsQueryResult.data?.user ?? null;
  const userTaskPermissions = taskPermissionsQueryResult.data?.user?.userTaskPermissions ?? [];
  const productTaskTypes = productTaskTypesQueryResult.data?.productTaskTypes.nodes ?? [];
  const wildcardUserTaskPermissions = userTaskPermissions.filter((x) => !x.product || !x.taskType);

  const products = uniqBy(
    productTaskTypes.map((x) => x.product),
    "id"
  );

  const taskTypes = uniqBy(
    productTaskTypes.map((x) => x.taskType),
    "id"
  );

  function handleWildcardProductChange(productId: string) {
    setWildcardProductId(productId);
    setWildcardTaskTypeId("");
  }

  function handleWildcardTaskTypeChange(taskTypeId: string) {
    setWildcardProductId("");
    setWildcardTaskTypeId(taskTypeId);
  }

  async function createWildcardUserPermission() {
    await createUserTaskPermission(
      wildcardProductId === "" ? null : wildcardProductId,
      wildcardTaskTypeId === "" ? null : wildcardTaskTypeId
    );
    setWildcardProductId("");
    setWildcardTaskTypeId("");
  }

  return {
    isLoading: taskPermissionsQueryResult.loading,
    handleClose,
    vendorId,
    onVendorChange,
    userTaskPermissions,
    productTaskTypes,
    wildcardUserTaskPermissions,
    createUserTaskPermission,
    deleteUserTaskPermission,
    products,
    taskTypes,
    wildcardProductId,
    wildcardTaskTypeId,
    handleWildcardProductChange,
    handleWildcardTaskTypeChange,
    createWildcardUserPermission,
    user,
  };
}
