import { Box, Button, Flex, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import { faBan, faEye } from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../components/app-icon";
import { PageHeading } from "../../components/page-heading";
import { Container } from "../../components/layout/container";
import { LayoutBase, MainNav } from "../../components/layout";
import { PanelGroup, PanelBottom, PanelTop } from "../../components/panels";

import { CustomSingleSelect } from "../../common/custom-selector";
import { ProductMultiSelect } from "../../common/product-selector";
import { TaskTypeMultiSelect } from "../../common/task-type-selector-v2";

import { TasksTable } from "./tasks-table";
import { useTasks } from "./use-tasks-hook";

export function TasksRoute() {
  const {
    viewItems,
    tasksQueryResult,
    filterCount,
    selectedView,
    productIds,
    taskTypeIds,
    clearFilters,
    onNextPage,
    onPreviousPage,
    onViewChange,
    onProductIdsChange,
    onTaskTypeIdsChange,
  } = useTasks();

  return (
    <LayoutBase pageTitle="Tasks">
      <MainNav />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Tasks</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">View</FormLabel>
                  <CustomSingleSelect
                    onChange={onViewChange}
                    allowEmpty={false}
                    value={selectedView}
                    items={viewItems}
                    selectorIcon={faEye}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Product</FormLabel>
                  <ProductMultiSelect
                    selectedProductIds={productIds as string[]}
                    onChange={onProductIdsChange}
                    showSelectAll={true}
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Task Type</FormLabel>
                  <TaskTypeMultiSelect
                    excludeCustomerReviewTaskTypes
                    selectedTaskTypeIds={taskTypeIds as string[]}
                    onChange={onTaskTypeIdsChange}
                    showSelectAll={true}
                  />
                </FormControl>
                <Box>
                  {filterCount > 0 && (
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  )}
                </Box>
              </HStack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <TasksTable tasksQueryResult={tasksQueryResult} onNextPage={onNextPage} onPreviousPage={onPreviousPage} />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}
